function Projects() {

  const projects = [
    {
      name: 'lmnd.dev',
      href: new URL('/', window.location.href).toString(),
      year: 2024
    },
    { 
      name: 'repeart',
      href: new URL('/repeart-demo', window.location.href).toString(),
      year: 2023
    },
  ]

  return (
    <div className="Projects h-full">
      <div class="text-sm flex flex-auto flex-row flex-wrap content-start">
        {
          projects.map((item) => { 
            return (
              <div class="align-center flex h-10 px-20 hover:shadow p-2 max-w-screen-lg w-full lg:w-5/6 lg:p-20">
                <p class="min-w-40">{item.name}</p>
                <a href={item.href} target="_blank" rel="noreferrer">
                  {item.href.replace(/^https?:\/\//, '')}
                </a>
              </div>
            )
          })
        }
      </div>
    </div>
)
}

export default Projects;
