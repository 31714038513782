import React, { useState, useEffect } from 'react';

function Repeart() {
  const images = [
    'https://cdn2.lmnd.dev/09B70652-A779-4CEE-AB22-A5B28114D678.jpg',
    'https://cdn2.lmnd.dev/8DD114D9-071E-4FA0-BEBE-888379134471.jpg',
    'https://cdn2.lmnd.dev/9BDA9D26-2FA1-498B-8210-44FBE873BF90.jpg',
    'https://cdn2.lmnd.dev/9EB6BEF8-9F8D-47CD-B959-3C1D05CB72A7.jpg',
    'https://cdn2.lmnd.dev/A4F028C3-5E1C-433E-BD33-D0895832F092.jpg',
    'https://cdn2.lmnd.dev/A90C7125-FCD0-400D-B07D-324081CE461A.jpg',
    'https://cdn2.lmnd.dev/B166ECAA-DB7A-422B-8757-20F43DE8FC80.jpg',
    'https://cdn2.lmnd.dev/BD814942-C8CE-4BDE-BEAD-76370DD7B1EF.jpg',
    'https://cdn2.lmnd.dev/C8C6D171-9E8E-4606-BA76-CFC2E2C45643.jpg',
    'https://cdn2.lmnd.dev/D006A9CC-AEA0-4BFB-8762-46A448901ED4.jpg',
    'https://cdn2.lmnd.dev/D18985C0-DC71-486F-8ADB-BE4FA6B98818.jpg',
    'https://cdn2.lmnd.dev/D51DBEC4-2DA4-468F-886B-0325DD269BD5.jpg',
    'https://cdn2.lmnd.dev/D861F2C3-F38D-4A98-AF7E-39CA347A001A.jpg',
    'https://cdn2.lmnd.dev/E32CBCF7-DA37-4A8E-9736-4A5D6C3FC00B.jpg',
    'https://cdn2.lmnd.dev/EF8388BA-D3D3-4716-B9B6-7CCFC0E398C3.jpg',
    'https://cdn2.lmnd.dev/EFB16452-FBBE-4E94-815C-551E8DECBF90.jpg',
    'https://cdn2.lmnd.dev/01583BAA-AAF5-4C8F-9D98-FB060CC65AE6.jpg',
    'https://cdn2.lmnd.dev/028116C0-BAEF-4F94-8A19-A40002538BE1.jpg',
    'https://cdn2.lmnd.dev/0BC3B6AB-0D10-4AF9-9F78-72B2FF595728.jpg',
    'https://cdn2.lmnd.dev/1819F7FE-DC44-4F96-951D-59E0EC3C1D00.jpg',
    'https://cdn2.lmnd.dev/2EEA0629-2072-442D-8456-99169915AAD5.jpg',
    'https://cdn2.lmnd.dev/32112DEF-EE5A-47DA-957A-182FCE1A4F98.jpg',
    'https://cdn2.lmnd.dev/369391A0-7EC4-47EC-A56E-2EBD56114BE1.jpg',
    'https://cdn2.lmnd.dev/390498C3-8C20-47E8-A705-0B765CDDF846.jpg',
    'https://cdn2.lmnd.dev/471D00C0-7AA0-4E9E-B0A7-CCDA4AC8A77D.jpg',
    'https://cdn2.lmnd.dev/48A8731C-F6DF-4A5B-A44F-9350303449DF.jpg',
    'https://cdn2.lmnd.dev/55A5703B-4E00-49DA-8230-B4876E1AD7E7.jpg',
    'https://cdn2.lmnd.dev/58206094-3895-4FA8-A503-16B2E66BB398.jpg',
    'https://cdn2.lmnd.dev/5A1FFA8B-CCF3-4588-A7D3-2500EA1CED43.jpg',
    'https://cdn2.lmnd.dev/5D008239-F620-48D3-ADD7-894AFC8C425C.jpg',
    'https://cdn2.lmnd.dev/5F208DC6-27A3-4695-B30B-9412A181D592.jpg',
    'https://cdn2.lmnd.dev/66422FB7-654C-4C74-AD41-9CEBADE083F5.jpg',
    'https://cdn2.lmnd.dev/6C2520C6-8B0B-4BC5-80EB-B1372E89280E.jpg',
    'https://cdn2.lmnd.dev/6D6BA8D5-4E5F-4B4B-9854-A69FA0BA8BB4.jpg',
    'https://cdn2.lmnd.dev/74EEAFC4-1B55-4D99-91DA-1211C65091EB.jpg',
    'https://cdn2.lmnd.dev/75DA4234-9D01-43FF-8CDC-FF6A559609A7.jpg',
    'https://cdn2.lmnd.dev/77E8C02D-532B-4D5F-AE45-30A2D5D60A4A.jpg',
    'https://cdn2.lmnd.dev/7F1C06D4-EF03-42DF-91CB-5D5FB3B5634C.jpg',
    'https://cdn2.lmnd.dev/834D7F22-F9DD-4811-B8C1-924775DD5FC8.jpg',
    'https://cdn2.lmnd.dev/89533AE9-A840-47B2-A99D-F600C724EEC4.jpg'
  ].sort(() => Math.random() - 0.5);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    document.title = 'repeart gallery | lmnd.dev';
  });

  return (
    <div className="Repeart h-full max-w-[80%] m-auto">
      <h1 className="text-4xl font-bold text-center m-8">
        repeart gallery
      </h1>
      <div className={`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2'} gap-1 overflow-auto`}>
        {images.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`example ${index + 1}`}
            className="w-full h-auto max-w-[800px] max-h-[800px] filter sepia transition-all duration-300 hover:filter-none"
          />
        ))}
      </div>
    </div>
  );
}

export default Repeart;
