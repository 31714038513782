

import AsciiArt from './homepages/AsciiArt';

function Home() {
  const homepages = [
    AsciiArt,
  ]

  const RandomHomepage = homepages[Math.floor(Math.random() * homepages.length)];

  return (
    <div className="Home h-full flex-col m-5">

      <RandomHomepage />

    </div>
)
}

export default Home;
